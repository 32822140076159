import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname?.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: 'list', element: <CategoryList /> },
            { path: 'new', element: <CategoryCreate /> },
            { path: ':id/edit', element: <CategoryEdit /> },
          ],
        },
        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/product/list" replace />, index: true },
            { path: 'list', element: <ProductList /> },
            { path: 'new', element: <ProductCreate /> },
            { path: ':id/edit', element: <ProductEdit /> },
            { path: ':id/foward', element: <ProductFoward /> },
          ],
        },

        {
          path: 'tracabilite',
          children: [
            
            { path: 'company', element: <TracabiliteCompanyList /> },
            { path: 'employee', element: <TracabiliteEmployeList /> },
           
          ],
        },

        {
          path: 'store',
          children: [
            { element: <Navigate to="/dashboard/store/list" replace />, index: true },
            { path: 'list', element: <StoreList /> },
            { path: 'new', element: <StoreCreate /> },
            { path: ':id/edit', element: <StoreEdit /> },
          ],
        },


        {
          path: 'users',
          children: [
            { element: <Navigate to="/dashboard/users/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserEdit /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },

        {
          path: 'supervisors',
          children: [
            { element: <Navigate to="/dashboard/supervisors/list" replace />, index: true },
            { path: 'list', element: <SupervisorList /> },
            { path: 'new', element: <SupervisorCreate /> },
            // { path: ':id/edit', element: <UserEdit /> },
            // { path: 'account', element: <UserAccount /> },
          ],
        },

        {
          path: 'customers',
          children: [
            { element: <Navigate to="/dashboard/customers/list" replace />, index: true },
            { path: 'list', element: <CustomerList /> },
            {path: 'achat',element:<AchatCustomerList/> },
            { path: 'new', element: <CustomerCreate /> },
            { path: ':id/edit', element: <CustomerEdit /> },
          ],
        },


        {
          path: 'fournisseurs',
          children: [
            { element: <Navigate to="/dashboard/fournisseurs/list" replace />, index: true },
            { path: 'list', element: <FournisseurList /> },
            { path: 'new', element: <FournisseurCreate /> },
            { path: ':id/edit', element: <FournisseurEdit /> },
            { path: ':id/update', element: <AchatFournisseurEdit /> },
            {path: 'achat',element:<AchatFournisseurCreate/> },
            {path: 'registre',element:<AchatFournisseurList/> },
            {path: 'paiment',element:<PaimentAchatFournisseurCreate/> },
            {path: 'deposit',element:<PaimentAchatFournisseurList/> },
          ],
        },

        {
          path: 'creditSales',
          children: [
            { element: <Navigate to="/dashboard/creditSales/list" replace />, index: true },
            { path: 'list', element: <CreditSalesList /> },
            // { path: 'new', element: <CustomerCreate /> },
            // { path: ':id/edit', element: <CustomerEdit /> },
          ],
        },


        {
          path: 'reduceSalaire',
          children: [
            { element: <Navigate to="/dashboard/reduceSalaire/list" replace />, index: true },
            { path: 'list', element: <ReductionList /> },
            { path: 'new', element: <ReduceSalaryList /> },
            // { path: 'new', element: <PayrollCreate /> },
            
          ],
        },

        {
          path: 'payroll',
          children: [
            { element: <Navigate to="/dashboard/payroll/list" replace />, index: true },
            { path: 'list', element: <PayrollList /> },
            { path: 'new', element: <PayrollCreate /> },
           
            { path: 'avance', element: <PayrollAvanceCreate /> },
            {path: ':id',element:<PayrollDetail/>}
          ],
        },


        {
          path: 'etatCaisse',
          children: [
            { element: <Navigate to="/dashboard/etatCaisse" replace />, index: true },
            { path: 'list', element: <EtatCaisse /> },
            { path: 'new', element: <EtatCaisseCreate /> },
            { path: ':id/edit', element: <EtatCaisseEdit /> },
          ],
        },


        {
          path: 'rapport',
          children: [
            { element: <Navigate to="/dashboard/rapport/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: 'tickets', element: <TicketList /> },
            { path: 'inventories', element: <ProductRep /> },
            { path: 'fournisor-inventories', element: <FournisorRep /> },
            { path: ':id', element: <InvoiceDetails /> },
            {path:  'transport', element:<TransportList/>}
          ],
        },

        {
          path: 'compteRendu',
          children: [
            { path: 'list', element: <CompteRenduList /> },
          
          ],
        },

        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// CATEGORY
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/CategoryList')));
const CategoryCreate = Loadable(lazy(() => import('../pages/dashboard/CategoryCreate')));
const CategoryEdit = Loadable(lazy(() => import('../pages/dashboard/CategoryEdit')));

// PORDUCT
const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));
const ProductEdit = Loadable(lazy(() => import('../pages/dashboard/ProductEdit')));
const ProductFoward = Loadable(lazy(() => import('../pages/dashboard/TransfertProduct')))

// STORE
const StoreList = Loadable(lazy(() => import('../pages/dashboard/StoreList')));
const StoreCreate = Loadable(lazy(() => import('../pages/dashboard/StoreCreate')));
const StoreEdit = Loadable(lazy(() => import('../pages/dashboard/StoreEdit')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const TicketList = Loadable(lazy(() => import('../pages/dashboard/TicketList')));
const ProductRep = Loadable(lazy(() => import('../pages/dashboard/ProductRep')));
const FournisorRep = Loadable(lazy(() => import('../pages/dashboard/InventaireFournisor')));
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));
const TransportList = Loadable(lazy(()=> import('../pages/dashboard/TransportList')));

// COMPTE RENDU
const CompteRenduList = Loadable(lazy(() => import('../pages/dashboard/CompteRenduList')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/UserEdit')));
// CUSTOMER
const CustomerList = Loadable(lazy(() => import('../pages/dashboard/CustomerList')));
const CustomerCreate = Loadable(lazy(() => import('../pages/dashboard/CustomerCreate')));
const CustomerEdit = Loadable(lazy(() => import('../pages/dashboard/CustomerEdit')));
const AchatCustomerList = Loadable(lazy(() => import('../pages/dashboard/achatCustomerList')));

// Fournisseur
const FournisseurCreate = Loadable(lazy(() => import('../pages/dashboard/FournisseurCreate')));
const FournisseurList=Loadable(lazy(() => import('../pages/dashboard/FournisseurList')));
const FournisseurEdit=Loadable(lazy(() => import('../pages/dashboard/FournisseurEdit')));
const AchatFournisseurCreate=Loadable(lazy(() => import('../pages/dashboard/ProduitFournisorCreate')));
const AchatFournisseurList=Loadable(lazy(() => import('../pages/dashboard/ProduitFourniList')));
const AchatFournisseurEdit=Loadable(lazy(() => import('../pages/dashboard/ProduitFourniEdit')));
const PaimentAchatFournisseurCreate=Loadable(lazy(() => import('../pages/dashboard/PaimentproduitFournisorCreate')));
const PaimentAchatFournisseurList=Loadable(lazy(() => import('../pages/dashboard/PaimentproduitFournisorList')));

// CreditSales
const CreditSalesList = Loadable(lazy(() => import('../pages/dashboard/CreditSalesList')));

// Supervisor 
const SupervisorList = Loadable(lazy(() => import('../pages/dashboard/SupervisorList')));
const SupervisorCreate = Loadable(lazy(() => import('../pages/dashboard/SupervisorCreate')));

// TRACABILITE
const TracabiliteEmployeList = Loadable(lazy(() => import('../pages/dashboard/TracabiliteEmployeList')));
const TracabiliteCompanyList = Loadable(lazy(() => import('../pages/dashboard/TracabiliteCompanyList')));



// PAYROLL
const PayrollAvanceCreate = Loadable(lazy(() => import('../pages/dashboard/PayrollAvanceCreate')));
const PayrollCreate = Loadable(lazy(() => import('../pages/dashboard/PayrollCreate')));
const ReduceSalaryList = Loadable(lazy(() => import('../pages/dashboard/ReduceSalaryList')));
const ReductionList = Loadable(lazy(() => import('../pages/dashboard/ReductionList')));
const PayrollList = Loadable(lazy(() => import('../pages/dashboard/PayrollList')));
// const PayrollDetails = Loadable(lazy(() => import('../pages/dashboard/PayrollDetail')));
const PayrollDetail = Loadable(lazy(() => import('../pages/dashboard/PayrollDetails')));

// Etat caisse
const  EtatCaisse= Loadable(lazy(() => import('../pages/dashboard/EtatCaisse')));
const  EtatCaisseCreate= Loadable(lazy(() => import('../pages/dashboard/EtatCaisseCreate')));
const EtatCaisseEdit = Loadable(lazy(() => import('../pages/dashboard/EtatCaisseEdit')))

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// MAIN
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
