// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_PAGE = {
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    list: path(ROOTS_DASHBOARD, '/category/list'),
    new: path(ROOTS_DASHBOARD, '/category/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/category/${id}/edit`),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/product/${id}/edit`),
    foward: (id) => path(ROOTS_DASHBOARD, `/product/${id}/foward`),
  },
  store: {
    root: path(ROOTS_DASHBOARD, '/store'),
    list: path(ROOTS_DASHBOARD, '/store/list'),
    new: path(ROOTS_DASHBOARD, '/store/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/store/${id}/edit`),
  },
  tracabilite: {
    root: path(ROOTS_DASHBOARD, '/tracabilite'),
    company: path(ROOTS_DASHBOARD, '/tracabilite/company'),
    employee: path(ROOTS_DASHBOARD, '/tracabilite/employee'),
    
  },
  rapport: {
    root: path(ROOTS_DASHBOARD, '/rapport'),
    list: path(ROOTS_DASHBOARD, '/rapport/list'),
    ticket: path(ROOTS_DASHBOARD, '/rapport/tickets'),
    product: path(ROOTS_DASHBOARD, '/rapport/inventories'),
    inventaire: path(ROOTS_DASHBOARD, '/rapport/fournisor-inventories'),
    transport: path(ROOTS_DASHBOARD, '/rapport/frais-transport)'),
    view: (id) => path(ROOTS_DASHBOARD, `/rapport/${id}`),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },

  compteRendu:{
    list: path(ROOTS_DASHBOARD,'/compteRendu/list'),
    
  },



  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    new: path(ROOTS_DASHBOARD, '/users/new'),
    list: path(ROOTS_DASHBOARD, '/users/list'),
    cards: path(ROOTS_DASHBOARD, '/users/cards'),
    profile: path(ROOTS_DASHBOARD, '/users/profile'),
    account: path(ROOTS_DASHBOARD, '/users/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/users/${id}/edit`),
  },
  supervisors: {
    root: path(ROOTS_DASHBOARD, '/supervisors'),
    new: path(ROOTS_DASHBOARD, '/supervisors/new'),
    list: path(ROOTS_DASHBOARD, '/supervisors/list'),
  }, 

  customers: {
    root: path(ROOTS_DASHBOARD, '/customers'),
    new: path(ROOTS_DASHBOARD, '/customers/new'),
    achat:path(ROOTS_DASHBOARD,'/customers/achat'),
    list: path(ROOTS_DASHBOARD, '/customers/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/customers/${id}/edit`),
  },


  fournisseurs: {
    root: path(ROOTS_DASHBOARD, '/fournisseurs'),
    new: path(ROOTS_DASHBOARD, '/fournisseurs/new'),
    list: path(ROOTS_DASHBOARD, '/fournisseurs/list'),
    achat: path(ROOTS_DASHBOARD, '/fournisseurs/achat'),
    registre: path(ROOTS_DASHBOARD, '/fournisseurs/registre'),
    paiment: path(ROOTS_DASHBOARD, '/fournisseurs/paiment'),
    deposit: path(ROOTS_DASHBOARD, '/fournisseurs/deposit'),
    edit: (id) => path(ROOTS_DASHBOARD, `/fournisseurs/${id}/edit`),
    update:(id) =>path(ROOTS_DASHBOARD, `/fournisseurs/${id}/update`),

  },

  creditSales: {
    root: path(ROOTS_DASHBOARD, '/creditSales'),
    // new: path(ROOTS_DASHBOARD, '/customers/new'),
    list: path(ROOTS_DASHBOARD, '/creditSales/list'),
    // edit: (id) => path(ROOTS_DASHBOARD, `/customers/${id}/edit`),
  },
  payroll:{
    list: path(ROOTS_DASHBOARD,'/payroll/list'),
    new: path(ROOTS_DASHBOARD, '/payroll/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/payroll/${id}`),
    avance: path(ROOTS_DASHBOARD, '/payroll/avance'),
  },

  etatCaisse:{
    root: path(ROOTS_DASHBOARD, '/etatCaisse'),
    list: path(ROOTS_DASHBOARD,'/etatCaisse/list'),
    new: path(ROOTS_DASHBOARD, '/etatCaisse/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/etatCaisse/${id}/edit`),
   
  },
  reduceSalaire:{
    list: path(ROOTS_DASHBOARD,'/reduceSalaire/list'),
    new: path(ROOTS_DASHBOARD, '/reduceSalaire/new'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

// export const PATH_DOCS = 'https://loyalttoservices.com';
